@import "vars_aurelio";

@font-face {
    font-family: "Montserrat";
    font-weight: 300;
    font-style: normal;
    src: url("/assets/site/fonts/Montserrat/Montserrat-Bold.woff");
    src: url("/assets/site/fonts/Montserrat/Montserrat-Light.eot?#iefix") format("embedded-opentype"), url("/assets/site/fonts/Montserrat/Montserrat-Light.woff") format("woff"), url("/assets/site/fonts/Montserrat/Montserrat-Light.ttf") format("truetype");
}

@font-face {
    font-family: "Montserrat";
    font-weight: 400;
    font-style: normal;
    src: url("/assets/site/fonts/Montserrat/Montserrat-Regular.eot");
    src: url("/assets/site/fonts/Montserrat/Montserrat-Regular.eot?#iefix") format("embedded-opentype"), url("/assets/site/fonts/Montserrat/Montserrat-Regular.woff") format("woff"), url("/assets/site/fonts/Montserrat/Montserrat-Regular.ttf") format("truetype");
}

@font-face {
    font-family: "Montserrat";
    font-weight: 500;
    font-style: normal;
    src: url("/assets/site/fonts/Montserrat/Montserrat-Medium.eot");
    src: url("/assets/site/fonts/Montserrat/Montserrat-Medium.eot?#iefix") format("embedded-opentype"), url("/assets/site/fonts/Montserrat/Montserrat-Medium.woff") format("woff"), url("/assets/site/fonts/Montserrat/Montserrat-Medium.ttf") format("truetype");
}

@font-face {
    font-family: "Montserrat";
    font-weight: 600;
    font-style: normal;
    src: url("/assets/site/fonts/Montserrat/Montserrat-SemiBold.eot");
    src: url("/assets/site/fonts/Montserrat/Montserrat-SemiBold.eot?#iefix") format("embedded-opentype"), url("/assets/site/fonts/Montserrat/Montserrat-SemiBold.woff") format("woff"), url("/assets/site/fonts/Montserrat/Montserrat-SemiBold.ttf") format("truetype");
}

@font-face {
    font-family: "Montserrat";
    font-weight: 700;
    font-style: normal;
    src: url("/assets/site/fonts/Montserrat/Montserrat-Bold.eot");
    src: url("/assets/site/fonts/Montserrat/Montserrat-Bold.eot?#iefix") format("embedded-opentype"), url("/assets/site/fonts/Montserrat/Montserrat-Bold.woff") format("woff"), url("/assets/site/fonts/Montserrat/Montserrat-Bold.ttf") format("truetype");
}

@font-face {
    font-family: "Montserrat";
    font-weight: 800;
    font-style: normal;
    src: url("../../landing-page//fonts/Montserrat/Montserrat-ExtraBold.eot");
    src: url("../../landing-page//fonts/Montserrat/Montserrat-ExtraBold.eot?#iefix") format("embedded-opentype"), url("../../landing-page//fonts/Montserrat/Montserrat-ExtraBold.woff") format("woff"), url("../../landing-page//fonts/Montserrat/Montserrat-ExtraBold.ttf") format("truetype");
}

@font-face {
    font-family: "Montserrat";
    font-weight: 900;
    font-style: normal;
    src: url("../../landing-page//fonts/Montserrat/Montserrat-Black.eot");
    src: url("../../landing-page//fonts/Montserrat/Montserrat-Black.eot?#iefix") format("embedded-opentype"), url("../../landing-page//fonts/Montserrat/Montserrat-Black.woff") format("woff"), url("../../landing-page//fonts/Montserrat/Montserrat-Black.ttf") format("truetype");
}

.padding-3 {
    padding: 0 3px;
}

.navbar  {
    border: none;
}

@media (max-width: 767px) {
    .navbar-nav .open .dropdown-menu {
        position: absolute;
        z-index: 999999;
        background-color: #fff;
        float: right;
        -webkit-box-shadow: 2px 2px 5px rgba(0, 0, 0, .4);
        box-shadow: 2px 2px 5px rgba(0, 0, 0, .4);
        top: 10px;
        right: 130px;
    }

    .navbar-nav .open .dropdown-menu .dropdown-header,
    .navbar-nav .open .dropdown-menu>li>a {
        padding: 5px 10px;
    }

    .navbar-nav .language-selector.open .dropdown-menu>li>a {
        padding: 5px 7px;
    }

    .collapse.in li>a,
    .collapsing li>a {
        color: $light-text-color !important;
    }

    .navbar .collapse.in,
    .navbar .collapsing {
        display: block;
        position: absolute;
        right: 25px;
        background: #fff;
        margin: 0 0 0 50px !important;
        border: none;
        padding: 0 20px;
        border-radius: 7px;
        z-index: 99999;
        -webkit-box-shadow: 2px 2px 5px rgba(0, 0, 0, .4);
        box-shadow: 2px 2px 5px rgba(0, 0, 0, .4);
        width: 200px;
    }

    .navbar.navbar-default #navbar.navbar-collapse.collapse.in li,
    .navbar.navbar-default #navbar.navbar-collapse.collapsing li {
        font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
    }

    .collapse.in .nav>li>a,
    .collapsing .nav>li>a {
        font-variant: normal;

        text-overflow: ellipsis;
        white-space: nowrap;
        overflow: hidden;
    }

    .navbar-collapse.in {
        overflow: visible;
    }

    .navbar-default .navbar-toggle .icon-bar {
        background-color: #79CAF7;
    }

    .language-selector.open .dropdown-menu {
        right: 0
    }

    .language-selector .dropdown-menu li {
        float: left;
    }

    .navbar-nav .language-selector.open .dropdown-menu>li>a {
        padding: 5px 7px;
    }
}

.navbar-nav { 
    .language-selector .dropdown-toggle, .user-menu-dropdown {
    padding-right: 0;
}
}

.navbar-nav .language-selector .caret {
    color: $light-text-color;
}

nav.navbar.navbar-default {
    margin-bottom: 20px;
}

nav .nav>li>a {
    color: $light-text-color;
    font-variant: small-caps;
    line-height: 30px;
}

nav i {
    width: 30px;
}

nav i.fal.fa-bell {
    width: 15px;
    font-size: 15px;
}

nav i.fal.fa-shield {
    font-size: 13px;
    padding-top: 3px;
    width: 0%;
}

.navbar-toggle i.fal.fa-bell,
.navbar-toggle i.fal.fa-users,
.navbar-toggle i.fal.fa-shield {
    color: $light-text-color;
}

.navbar-toggle i.fal.fa-bell.home,
.navbar-toggle i.fal.fa-users.home,
.navbar-toggle i.fal.fa-shield.home {
    color: #fff;
}

.notification-badge {
    position: relative;
    top: -11px;
    right: 9px;
    background-color: $main-color;
    font-weight: 400;
}

.navbar-brand {
    padding: 8px 4px;
    margin-right: 30px;
}

.navbar {
    min-height: initial;
    margin-bottom: 5px;
}

.navbar.navbar-default.top:after {
    content: '';
    position: absolute;
    display: block;
    left: 0;
    right: 0;
    top: 100%;
    margin: 5px auto;
    height: 1px;
    background: linear-gradient(90deg, transparent 0%, $logo-secondary-color 40%, $main-color 60%, transparent 100%);
    opacity: .5;
}

img.flag {
    max-height: 20px;
}

.navbar-default .navbar-nav>.active>a,
.navbar-default .navbar-nav>.active>a:focus,
.navbar-default .navbar-nav>.active>a:hover,
.navbar-default .navbar-toggle:focus,
.navbar-default .navbar-toggle:hover {
    color: #fff;
    background-color: transparent;
}

.navbar-default .navbar-nav>.open>a,
.navbar-default .navbar-nav>.open>a:focus,
.navbar-default .navbar-nav>.open>a:hover {
    background-color: transparent;
}

.language-selector .dropdown-menu {
    min-width: 0;
}

.language-selector .dropdown-menu li a.language:hover {
    background-color: transparent;
}

.language-selector .dropdown-menu .flag:hover {
    border: 1px solid #fff;
}

.navbar-right {
    display: flex;
}

.navbar-right .dropdown-menu {
    z-index: 99999;
    border-radius: 7px !important;
    border: none;
    -webkit-box-shadow: 2px 2px 5px rgba(0, 0, 0, .4);
    box-shadow: 2px 2px 5px rgba(0, 0, 0, .4);
    font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
}

.navbar-right .dropdown-menu li {
    padding: 2px 5px;
}



.navbar-right .dropdown-menu li i {
    color: #929292;
    width: 30px;
    font-weight: 100;
}

.navbar-right .dropdown-menu>li>a {
    padding: 5px 10px;
}

.navbar-default .navbar-toggle {
    border: none;
}

.navbar-right .dropdown-menu-right li a:hover {
    background-color: $background-light;
    border-radius: 5px;
}

.navbar-right .favorites-dropdown {
  .dropdown-menu {
    top: 0;
    right: 100%;

    li > a > i.fas {
      font-weight: 900;
    }
  }

  &:hover .dropdown-menu {
    display: block;
  }
}

.navbar-right {
  .favorites-expand-toggle {
    .open-icon {
      display: none;
    }

    &[aria-expanded=true] {
      .open-icon {
        display: inline-block;
      }
      .closed-icon {
        display: none;
      }
    }
  } 

  .favorites-expand {
    display: none;

    > a {
      display: block;
      padding: 10px 15px;
      color: #323232;

      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }

  .visible-xs {
    max-width: 100%;

    &.open {
      .favorites-expand {
        display: block;
      }
    }
  }
}

@media (min-width: 768px) {
    .navbar .h-divider {
        display: none;
    }

    .nav.navbar-nav.navbar-right li.dropdown a.dropdown-toggle .fal.fa-user {
        width: 20px;
    }
}

@media (max-width: 768px) {
    .nav.navbar-nav.navbar-right li.dropdown a.dropdown-toggle .fal.fa-user {
        width: 30px;
    }
}


.navbar-default {
    background-color: #fff;
    border-color: transparent;
    margin-bottom: 0;
}

.navbar-default .nav.navbar-nav,
.navbar-default .nav.navbar-nav.navbar-right {
    font-family: 'Montserrat', Helvetica, sans-serif;
}

.navbar-header .fa.fa-credit-card.fa-fw {
    float: right;
    color: $light-text-color;
    padding: 10px;
}

.navbar-header .form-group {
    margin-bottom: 0;
    margin-right: 5px;
}

.navbar .btn {
    border-radius: 7px;
    background-color: $background-light;
    color: $light-text-color;
}

.navbar.navbar-default .navbar-header .dropdown {
    display: inline-block;
    margin-right: 5px;
}

.container>.navbar-header {
    margin-right: 5px;
    margin-left: 5px;
}

.navbar-header .fa.fa-credit-card.fa-fw {
    float: right;
    color: $light-text-color;
    padding: 10px;
}

.navbar-header .form-group {
    margin-bottom: 0;
    margin-right: 5px;
}

.navbar.navbar-default.no-padding {
    border-bottom: 0;
}

.nexxchange-logo {
    height: 35px;
    content: url('/assets/site/img/logo-navbar.png');
}

.container.padding-0 .nav>li>a {
    height: 50px;
}

/*.container.padding-0 .nav > li > a:hover {
    color: $dark-text-color !important;
}*/
.navbar-brand img:hover {
    opacity: .8;
}

.nav>li>a:focus,
.nav>li>a:hover,
.nav .open>a,
.nav .open>a:focus,
.nav .open>a:hover {
    text-decoration: none;
    background-color: transparent;
}

hr.solid {
    margin: 5px;
    border-color: $border-color;
}

@media only screen and (min-width: 992px) {
    .navbar-default {
        padding: 0;
    }

    .navbar-brand {
        padding: 10px 7px;
    }
}

@media (min-width: 768px) {

    .navbar>.container .navbar-brand,
    .navbar>.container-fluid .navbar-brand {
        margin-left: -12px;
    }
    
}

@media (max-width: 768px) {

    .nexxchange-logo {
        height: 30px !important;
        margin-left: 5px;
    }
    
}

@media only screen and (max-width: 500px) {
    .nexxchange-logo {
        height: 25px !important;
    }

    .navbar-brand {
        padding: 10px 0px;
    }

    .navbar-toggle {
        padding: 9px 5px;
    }

    a.navbar-toggle {
        margin-right: 0;
    }
    .navbar.navbar-default.top {
       margin-bottom: 0;
    }
    .navbar.navbar-default.top:after {
        display: none;
     }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
    .navbar-title {
        display: none;
    }

    .fa-calendar-alt,
    .fa-trophy {
        font-size: 20px;
    }
}